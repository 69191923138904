<script lang="ts" setup>
import type { GameModes } from "@/types";

const { t } = useT();
const { tourPoints, entries, winnings } = useBalance();
const { isSweepStakes, activeGameMode, handleSwitchGameMode } = useSwitchMode();

const balance = ref(+entries.value || 0);

const handleSwitchToMode = async (mode: GameModes) => {
	dispatchGAEvent({
		event: "click_button",
		location: "header",
		button_name: isSweepStakes.value ? "switch_tourney" : "switch_promotional"
	});
	await handleSwitchGameMode(mode);
};

const handleHiwClick = () => {
	navigateTo("/how-it-works/");
};

const currentIcon = computed(() => `20/${activeGameMode.value}`);

const showAnimation = useState<boolean>("animationCoinPouring", () => false);

const triggerAnimation = () => {
	showAnimation.value = true;
	setTimeout(() => {
		showAnimation.value = false;
	}, 3000);
};

watch(entries, (value) => {
	triggerAnimation();
	balance.value = +value;
});

watch(tourPoints, () => {
	triggerAnimation();
});
</script>
<template>
	<APopper placement="bottom">
		<div id="entries-container" class="balance-switcher" data-tid="header-balances">
			<MPrizeFund :variant="activeGameMode" :iconSize="16" :icon="currentIcon">
				<div v-if="isSweepStakes">
					<AText class="text-chofu" :modifiers="['semibold', 'ellipsis']" as="div">
						<AAnimationNumberCounter :number="balance" />
					</AText>
					<AText variant="ternopil" class="text-balance text-caracas" :modifiers="['uppercase']" as="div">
						{{ t("Win") }}:<AText variant="ternopil" class="text-cannes"
							><AAnimationNumberCounter :number="Number(winnings ?? 0)" :decimals="2"
						/></AText>
					</AText>
				</div>
				<AText v-else class="text-coimbatore" :modifiers="['semibold', 'ellipsis']" as="div" data-tid="show-balance">
					<AAnimationNumberCounter :number="Number(tourPoints)" />
				</AText>
				<NuxtIcon class="arrow-icon" name="24/arrow-down" data-tid="open-balances-list" filled />
			</MPrizeFund>
		</div>
		<template #content="{ close }">
			<div :class="['item', { selected: !isSweepStakes }]" @click="handleSwitchToMode('TournamentPoints'), close()">
				<MPrizeFund variant="coins" :iconSize="20" icon="20/coins">
					<AText class="text-cannes" :modifiers="['semibold']">{{ t("Gold Coins") }}</AText>
				</MPrizeFund>
				<AText class="item-balance text-coimbatore" :modifiers="['semibold', 'right']" as="div" data-tid="points-coins">
					{{ numberFormat(tourPoints) }}
				</AText>
			</div>
			<div :class="['item', { selected: isSweepStakes }]" @click="handleSwitchToMode('SweepStakes'), close()">
				<MPrizeFund variant="entries" :iconSize="20" icon="20/entries">
					<AText class="text-cannes" :modifiers="['semibold']">{{ t("Super Coins") }}</AText>
				</MPrizeFund>
				<div class="item-balance">
					<AText :modifiers="['semibold', 'right']" class="text-changchun" as="div" data-tid="entries-coins">
						{{ numberFormat(entries) }}
					</AText>
					<AText
						class="text-balance text-caracas"
						variant="ternopil"
						:modifiers="['right', 'normal', 'uppercase']"
						as="div"
					>
						{{ t("Win") }}:<AText variant="ternopil" class="text-cannes" data-tid="winnings-coins">
							<AAnimationNumberCounter :number="Number(winnings ?? 0)" :decimals="2"
						/></AText>
					</AText>
				</div>
			</div>
			<div class="divider" />
			<AText :modifiers="['center', 'link', 'underline']" as="div" data-tid="info-link" @click="handleHiwClick">
				{{ t("How it works") }}?
			</AText>
		</template>
	</APopper>
</template>
<style scoped lang="scss">
.balance-switcher {
	height: 40px;
	background: var(--chaozhou);
	padding: 0 gutter(1.5) 0 gutter(1);
	border-radius: 8px 0 0 8px;
	margin-right: gutter(-2.5);
	display: flex;
	align-items: center;
	cursor: pointer;

	.arrow-icon {
		font-size: 20px;
		margin: 0 gutter(0.5);
	}
	.ellipsis {
		min-width: 50px;
		max-width: 110px;
	}
	@include media-breakpoint-up(lg) {
		padding: 0 gutter(3) 0 gutter(1);
	}
	@include media-breakpoint-down(sm) {
		:deep(.prize) {
			gap: gutter(0.5);
		}
	}
}
.text-balance {
	display: flex;
	gap: gutter(0.25);
}
:deep(.popper) {
	--popper-theme-background-color: var(--chaozhou);
	--popper-theme-background-color-hover: var(--chaozhou);
	--popper-theme-border-width: 0px;
	--popper-theme-box-shadow: 0px 10px 15px -3px var(--coquimbo);

	width: 294px;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	gap: gutter(1);
	.divider {
		width: 100%;
		height: 1px;
		margin-top: gutter(0.5);
		background: var(--chers);
	}
	.item {
		padding: 0 gutter(1.5);
		height: 36px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: gutter(1);
		border-radius: 8px;
		transition: all 200ms ease-in-out;
		&.selected {
			background: var(--chandigarh);
		}
		&:not(.selected) {
			cursor: pointer;
			&:hover {
				background: var(--casablanca);
			}
		}
	}
}
.is-blurred {
	transition: 0.2s filter 8s ease-in-out;
	filter: blur(4px);
	&:hover {
		filter: blur(0);
		transition-delay: 0s;
		@include media-breakpoint-down(lg) {
			filter: blur(4px);
			transition-delay: 8s;
		}
	}
	&.out {
		filter: blur(0);
		transition-delay: 0s;
	}
}
</style>
